$primary-color: #545454 !default;
$secondary-color: #282828 !default;

$register-color-primary: #3F3F3F;
$register-color-secondary: #202020;
$register-color-text: white;
$register-color-links: white !important;

@font-face {
	font-family: 'Ubuntu-Habbo';
	src: url('/webfonts/Ubuntu-Habbo.woff2');
}
/* START HEADER */
/* END HEADER */
/* START NAV */
/* END NAV */
/* START ROUNDED-BUTTON STRUCTURE */
/* END ROUNDED-BUTTON STRUCTURE */
/* START ROUNDED-BUTTON COLORS */
/* END ROUNDED-BUTTON COLORS */
/* START INPUT STRUCTURE */
/* END INPUT STRUCTURE */
/* START REAL-CONTENT */
/* START STORYLINE */
/* END STORYLINE */
/* END REAL-CONTENT */
/* START CAROUSEL */
/* END CAROUSEL */
/* START DARK AREA */
/* END DARK AREA */
/* START REAL FOOTER */
/* END REAL FOOTER */
body {
	font-family: "Ubuntu", sans-serif;
	background: #FFF;
	overflow-x: hidden;
}
.blur {
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
}
.modal {
	background-color: rgba(0,0,0,.7);
}
.register-modal{
	.modal-content
	{
		background: linear-gradient($register-color-primary, $register-color-secondary);
		color: $register-color-text;

		a{
			color:$register-color-links;
		}
	}
}
.darkside {
	position: fixed;
	z-index: 99999;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: none;
	background: rgba(0,0,0,.5);
	color: #FFF;
}
.darkside.active {
	display: block;
}
.dropdown-menu {
	border-radius: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border: 1px solid rgba(0,0,0,.125);
	font-size: 14px;
	margin-top: 7px;
	a {
		color: #6c757d;
	}
}
input {
	&:focus {
		outline: none;
	}
}
.custom-select {
	&:focus {
		outline: none;
		border: 1px solid #ced4da;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	}
	border: 1px solid #ced4da;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	font-size: 14px;
	height: 38px;
	border-radius: 4px;
}
textarea {
	&:focus {
		outline: none;
	}
}
button {
	border: none;
	&:focus {
		outline: none;
	}
}
a {
	&:focus {
		outline: none;
	}
}
form {
	margin: 0;
}
.main-header {
	width: 100%;
	height: 458px;
	background: url(/images/header.png) center, #000;
	color: #FFF;
	background-size:cover;
	.logo {
		margin-bottom: 40px;
	}
	.sub-logo {
		text-align: center;
		font-size: 20px;
		color: #FFF;
		padding: 5px;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.login-heading {
		color: #FFF;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	}
	.login-heading-m {
		color: #303030;
		text-shadow: 2px 2px 1px rgba(0,0,0,.2);
		margin-top: 150px;
	}
}
nav {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	background: #000000;
	border-top: 1px solid rgba(255,255,255,.1);
}
.square-button {
	color: #FFF !important;
	text-align: center;
	cursor: pointer;
	text-shadow: 0px 2px 1px rgba(0,0,0,.2);
	transition: all 0.3s ease 0s;
	font-size: 16px;
	padding: 10px 30px;
	text-decoration: none;
	white-space: nowrap;
	&:hover {
		box-shadow: 0 0 0 4px rgba(255,255,255,.4);
		text-decoration: none;
	}
}
.rounded-button {
	border-radius: 40px;
	color: #FFF !important;
	text-align: center;
	cursor: pointer;
	text-shadow: 0px 2px 1px rgba(0,0,0,.2);
	transition: all 0.3s ease 0s;
	font-size: 16px;
	padding: 10px 30px;
	text-decoration: none;
	white-space: nowrap;
	&:hover {
		box-shadow: 0 0 0 4px rgba(255,255,255,.4);
		text-decoration: none;
	}
}
.rounded-button.large-button {
	font-size: 20px;
	padding: 15px 40px;
}
.rounded-button.small-button {
	font-size: 14px;
	padding: 8px 15px;
}
.rounded-button.purple-button {
	background: linear-gradient(#dc0065, #6d1154);
}
.rounded-button.default-cms-button {
	background: linear-gradient($primary-color, $secondary-color);
}

.square-button.default-cms-button{
	background: linear-gradient(35deg, #FF091E, #FFB513);
	border-radius:4px;
	font-weight: bold;
}

.forgot-password {
	font-size: 14px;
	float: right;
	color: #FFF;
	width: 100%;
	text-align: right;
	margin-bottom: 10px;
	text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	&:hover {
		font-size: 14px;
		float: right;
		color: #FFF;
		width: 100%;
		text-align: right;
		margin-bottom: 10px;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	}
	&:active {
		font-size: 14px;
		float: right;
		color: #FFF;
		width: 100%;
		text-align: right;
		margin-bottom: 10px;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	}
	&:visited {
		font-size: 14px;
		float: right;
		color: #FFF;
		width: 100%;
		text-align: right;
		margin-bottom: 10px;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	}
}
.forgot-password-m {
	font-size: 14px;
	color: #303030;
	text-shadow: 2px 2px 1px rgba(0,0,0,.2);
	&:hover {
		font-size: 14px;
		color: #303030;
		text-shadow: 2px 2px 1px rgba(0,0,0,.2);
	}
	&:active {
		font-size: 14px;
		color: #303030;
		text-shadow: 2px 2px 1px rgba(0,0,0,.2);
	}
	&:visited {
		font-size: 14px;
		color: #303030;
		text-shadow: 2px 2px 1px rgba(0,0,0,.2);
	}
}
.input-group {
	box-shadow: 0 0 0 2px rgba(255,255,255,.4);
	margin-bottom: 10px;
	border-radius: 5px;
}
.input-group.large {
	height: 50px;
	input[type="text"] {
		height: 50px;
	}
	input[type="password"] {
		height: 50px;
	}
}
.input-group-prepend {
	border-right: 1px solid rgba(0,0,0,.1);
}
input[type="text"] {
	border: 1px solid #ced4da;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	font-size: 14px;
	height: 38px;
	border-radius: 4px;
	&:focus {
		border: 1px solid #ced4da;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	}
}
input[type="password"] {
	border: 1px solid #ced4da;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	font-size: 14px;
	height: 38px;
	border-radius: 4px;
	&:focus {
		border: 1px solid #ced4da;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	}
}
.form-check-label {
	font-size: 14px;
	color: #6c757d;
}
.real-content {
	padding-top: 20px;
	h4 {
		color: #202020;
		text-shadow: 2px 2px 1px rgba(0,0,0,.1);
		margin-bottom: 20px;
	}
}
.badge {
	font-weight: 400;
}
.card {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	border: 1px solid rgba(0,0,0,.125);
	font-size: 12px;
	border-radius: 4px;
	color: #6c757d;
	.heading {
		height: 300px;
		background-position: right;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		text-shadow: 2px 2px 1px rgba(0,0,0,.1);
		a {
			color: #FFF;
		}
		.writer {
			width: 140px;
			height: 300px;
			position: absolute;
			background-repeat: no-repeat;
			background-position: -10px 140px;
			z-index: 9;
		}
		.badge {
			position: absolute;
			margin: 5px;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16);
			font-size: 11px;
			display: block;
			padding: 5px;
		}
		.information {
			margin-top: 262px;
			width: 100%;
			background: rgba(0,0,0,.5);
			color: #FFF;
			padding: 10px 10px 10px 100px;
		}
	}
	.options {
		padding: 10px;
		text-align: right;
		border-top: 1px solid rgba(0,0,0,.1);
	}
	.card-body {
		p {
			&:last-child {
				margin: 0;
			}
		}
	}
}
.comenting {
	.title {
		display: block;
		width: 100%;
		font-size: 14px;
		font-weight: bold;
		color: #303030;
		margin-right: 5px;
	}
	.desc {
		font-size: 12px;
		color: #6c757d;
	}
	.avatar {
		width: 50px;
		height: 60px;
		background-position: -9px -10px;
		float: left;
	}
	.options {
		border-top: 1px dashed rgba(0,0,0,.1);
		margin-top: 5px;
		width: 100%;
		text-align: right;
	}
	small {
		float: right;
		color: #6c757d;
		font-size: 11px;
	}
	.reply {
		border-top: 1px solid rgba(0,0,0, .1);
		.options {
			padding-top: 4px;
		}
	}
}
.options {
	a {
		font-size: 12px;
		margin-right: 5px;
		color: #6c757d;
	}
}
.row {
	margin-bottom: 30px;
}
.list-group {
	border-radius: 4px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
}
.list-group.articles {
	.list-group-item {
		h5 {
			font-size: 14px;
			font-weight: bold;
		}
	}
	.text-muted {
		float: left;
	}
}
.list-group-item.more {
	text-align: right;
	transition: all 0.3s ease 0s;
	&:hover {
		text-align: right;
		background: #00bcd4;
		color: #FFF;
	}
}
.btn {
	font-size: 14px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
}
.tag {
	font-family: "Ubuntu-Habbo", sans-serif;
	font-weight: normal;
	display: inline-block;
	padding: .25em .4em;
	font-size: 75%;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	background: #28a745;
	border-radius: 4px;
	cursor: pointer;
	color: #FFF;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	transition: all 0.3s ease 0s;
	&:hover {
		background: #dc3545;
	}
}
.image-window {
	width: 70px;
	height: 70px;
	border: 2px solid rgba(0,0,0,.1);
	float: left;
	border-radius: 100px;
	margin-right: 10px;
}
.allarticles {
	.card {
		margin-bottom: 20px;
	}
}
.hall-item {
	&:hover {
		text-decoration: none;
	}
	.title {
		margin-top: 15px;
		font-size: 14px;
		font-weight: bold;
	}
	.desc {
		font-size: 12px;
		color: #6c757d;
	}
}
.my-info {
	.gray-box {
		height: 300px;
		background: #CBCBCB;
		color: rgba(0,0,0,.4);
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		font-weight: bold;
		border-radius: 4px;
		overflow: hidden;
	}
	.white-box {
		padding: 5px 20px;
		font-size: 14px;
		background: #FFF;
		border-bottom: 1px solid rgba(0,0,0,.2);
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	}
	.white-inverted-box {
		font-family: "Ubuntu-Habbo", sans-serif;
		padding: 5px 20px 5px 28px;
		font-size: 11px;
		background: url(/images/motto.png) no-repeat 8px 5px, #FFF;
		border-top: 1px solid rgba(0,0,0,.2);
		box-shadow: 0 -3px 6px rgba(0,0,0,0.16);
	}
	.username {
		background-image: -webkit-linear-gradient(92deg,$primary-color, $secondary-color);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 16px;
	}
	.last-login {
		font-size: 11px;
		text-align: center;
	}
	.avatar-area {
		height: 190px;
		background: url(/images/base.png) no-repeat center bottom;
		.avatar {
			background-repeat: no-repeat;
			background-position: 90px 40px;
			height: 191px;
		}
	}
	.wallet {
		position: absolute;
		float: left;
		top: 83px;
		white-space: nowrap;
		.variable {
			background-color: #FFF;
			border-bottom: 1px solid rgba(0,0,0,.2);
			box-shadow: 0 3px 6px rgba(0,0,0,0.16);
			color: #202020;
			font-size: 12px;
			line-height: 38px;
			overflow: hidden;
			max-width: 38px;
			padding-left: 38px;
			transition: all 0.5s ease 0s;
			height: 38px;
			&:hover {
				max-width: 200px;
				padding-right: 10px;
			}
			.icon {
				background-position: center;
				position: absolute;
				margin-left: -38px;
				background-repeat: no-repeat;
				width: 38px;
				height: 38px;
			}
			span {
				font-weight: bold;
				font-family: "Open Sans", sans-serif;
			}
		}
	}
}
.gallery-picture {
	margin-bottom: 10px;
}
.users-online {
	text-align: right;
	margin-top: 20px;
	margin-bottom: 40px;
}
.loading-content {
	padding: 20px;
	font-size: 50px;
	text-align: center;
}
.adsense {
	position: relative;
	display: block;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0,0,0,.125);
	border-radius: 4px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	overflow: hidden;
	.title {
		font-size: 10px;
		padding: 5px;
		background: #3a5763;
		color: #FFF;
	}
}
.storyline {
	float: left;
	width: 100%;
	height: 105px;
	overflow: hidden;
	overflow-x: auto;
	.story {
		width: 80px;
		height: 80px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		background-size: cover;
		float: left;
		border-radius: 100px;
		margin-right: 10px;
		display: inline-block;
		cursor: pointer;
		transition: all 0.3s ease 0s;
		&:hover {
			&:after {
				opacity: 1;
			}
		}
		&:after {
			transition: all 0.2s ease 0s;
			float: left;
			font-weight: bold;
			width: 80px;
			opacity: 0;
			margin-top: 85px;
			text-align: center;
			line-height: 10px;
			white-space: nowrap;
			content: attr(data-user);
			color: rgba(0,0,0,.5);
			font-size: 12px;
		}
	}
	.mystory {
		.story {
			&:after {
				opacity: 1;
				content: 'Você';
			}
		}
		margin-right: 10px;
		float: left;
		height: 80px;
		width: 90px;
		border-right: 1px dashed rgba(0,0,0,.3);
	}
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track {
		background: rgba(0,0,0,.05);
		border-radius: 4px;
		border: none;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,.2);
		border-radius: 4px;
		&:hover {
			background: rgba(0,0,0,.3);
		}
	}
}
.stories-area {
	width: 900%;
}
#story-theatre {
	.carousel {
		width: 320px;
		height: 320px;
		border-radius: 4px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		position: absolute;
		left: 50%;
		margin-left: -160px;
		top: 50%;
		margin-top: -160px;
		font-size: 20px;
		img {
			border-radius: 4px;
		}
	}
	.carousel-item {
		width: 320px;
		height: 320px;
	}
	.progress {
		border-radius: 0;
		transition: width .2s ease;
	}
	.progress-bar {
		border-radius: 0;
		transition: width .2s ease;
	}
	h2 {
		margin: 30px;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	}
	.sent {
		position: absolute;
		width: 320px;
		text-align: right;
		margin-top: -25px;
		font-size: 12px;
	}
	.user {
		position: absolute;
		width: 320px;
		text-align: left;
		margin-top: -25px;
		font-size: 12px;
	}
	.close-btn {
		position: absolute;
		right: 30px;
		top: 30px;
		padding: 9px 12px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		font-size: 20px;
		color: #FFF;
		border-radius: 4px;
		background: rgba(255,255,255,.5);
		cursor: pointer;
	}
}
.tooltip {
	z-index: 999999999999;
}
.reaction {
	width: 60px;
	float: left;
}
.reaction-bar {
	position: absolute;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	border-radius: 4px;
	margin-top: 340px;
	background: rgba(255,255,255,.5);
	width: 240px;
	left: 50%;
	margin-left: -120px;
}
.news-carousel {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16) !important;
	border: 1px solid rgba(0,0,0,.125) !important;
	border-radius: 4px !important;
	overflow: hidden !important;
	.carousel-item {
		width: 730px !important;
		height: 300px !important;
		background-size: cover !important;
	}
}
.carousel-caption {
	background: rgba(0,0,0,.3) !important;
	text-align: left !important;
	right: 0 !important;
	left: 0 !important;
	bottom: 0px !important;
	padding: 10px 20px 0px 20px !important;
}
.carousel-control-prev {
	cursor: pointer;
	color: #FFF ;
	font-size: 22px ;
	top: 50% ;
	margin-top: -11px ;
	bottom: 50% ;
}
.carousel-control-next {
	cursor: pointer;
	color: #FFF ;
	font-size: 22px ;
	top: 50% ;
	margin-top: -11px ;
	bottom: 50% ;
}
.carousel {
	h5 {
		font-size: 16px !important;
	}
	p {
		font-size: 14px!important;
		opacity: .7!important;
	}
}
.carousel-indicators {
	bottom: -10px;
}
.dark-area {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.16);
	padding: 10px;
	background: url(https://i.imgur.com/6cINf5L.png) bottom repeat-x, url(https://i.imgur.com/wjn4T33.png) no-repeat center top, url(https://i.imgur.com/0PNO1ZT.png) top;
	margin-bottom: 20px;
	min-height: 400px;
	.jumbotron {
		background: transparent;
		text-shadow: 2px 2px 1px rgba(0,0,0,.5);
		color: #FFF;
	}
}
.real-footer {
	background: #000;
	box-shadow: 0 -3px 6px rgba(0,0,0,0.16);
	text-shadow: 2px 2px 1px rgba(0,0,0,.5);
	padding: 10px;
	font-size: 12px;
	color: rgba(255,255,255,.5);
	i {
		font-size: 18px;
		color: rgba(255,255,255,.5);
		transition: all 0.3s ease 0s;
		&:hover {
			color: #FFF;
		}
	}
}
